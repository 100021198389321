<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll>
        <inputItem v-if="detail" title="申请人员" :initialContent="detail.user_name" :disabled="true"></inputItem>
        <inputItem v-if="detail" title="打卡时间" :initialContent="formatDate(detail.time, 'yyyy-MM-dd hh:mm')" :disabled="true"></inputItem>
        <inputItem v-if="detail" title="申请时间" :initialContent="formatDate(detail.add_time, 'yyyy-MM-dd hh:mm')" :disabled="true"></inputItem>
        <inputItem v-if="detail" title="打卡类型" :initialContent="detail.type == 0 ? '上班' : '下班'" :disabled="true"></inputItem>
        <inputItem v-if="detail" title="打卡地址" :initialContent="detail.address" :disabled="true"></inputItem>
        <inputItem v-if="detail" title="打卡备注" :initialContent="detail.remark" :disabled="true"></inputItem>
        <div id="map_container" class="full-line" style="flex: 0 0 200px;height: 200px;"></div>
        <div v-if="flows && flows.flowLogs && flows.flowLogs.length > 0" class="mobile-detail-step">
          <Steps :current="flows.step" :status="flows.status" direction="vertical">
            <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName" :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
          </Steps>
        </div>
      </cube-scroll>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
  </div>
</template>

<script>
import { GetTimecardLog, AuditTimecardLog } from "@/api";
import { formatDate } from "@/utils/format.js";
import { loadDetailAction, auditAction } from "@/utils/action.js";

const AMap = window.AMap;
export default {
  data: function () {
    return {
      id: "",
      detail: null,
      flows: [],
      buttons: [],
      map: null
    };
  },
  methods: {
    formatDate: formatDate,
    initMap: function () {
      this.map = new AMap.Map("map_container", {
        center: [this.detail.longitude, this.detail.latitude],
        resizeEnable: true,
        zoom: 14,
      });
      var that = this;
      // eslint-disable-next-line no-undef
      AMapUI.loadUI(['control/BasicControl'], function (BasicControl) {
        // eslint-disable-next-line no-undef
        that.map.addControl(new BasicControl.LayerSwitcher({
          position: 'rt' //right top，右上角
        }));
      })
      var marker = new AMap.Marker({
        position: new AMap.LngLat(this.detail.longitude, this.detail.latitude)
      });
      this.map.add(marker);
    },
    buttonClick: function (title) {
      switch (title) {
        case "审核通过":
          this.auditTimecardLog(true, "同意");
          break;
        case "审核拒绝":
          this.auditTimecardLog(false, "");
          break;
      }
    },
    auditTimecardLog: function (pass, memo) {
      auditAction(this, AuditTimecardLog, this.id, pass, memo);
    },
    dataRequest: function () {
      loadDetailAction(this, GetTimecardLog, { id: this.id }, (data) => {
        this.detail = data.model;
        this.flows = data.flows;
        this.buttons = data.buttons;
        this.initMap();
      });
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>